import { PrypcoEnv } from './models/prypco-env.model';

const baseUrl = 'https://dev-prypto.azure-api.net';
const leadBaseApi = `${baseUrl}/lead`;
const userBaseApi = `${baseUrl}/user`;
const referenceBaseApi = `${baseUrl}/reference`;
const notificationBaseApi = `${baseUrl}/notification`;
const propertyInvestment = `${baseUrl}/investmentproperty`;

export const prypcoEnv: PrypcoEnv = {
  endpoints: {
    leadBase: leadBaseApi,
    userBaseApi,
    notificationBaseApi,
    mortgageLeads: `${leadBaseApi}/Mortgages/Leads`,
    servicesLeads: `${leadBaseApi}/Services/Leads`,
    blocksLead: `${leadBaseApi}/Blocks/Leads`,
    applicationForms: `${leadBaseApi}/ApplicationForms`,
    onboarding: `${leadBaseApi}/Onboarding`,
    dictionaries: `${leadBaseApi}/Dictionaries`,
    applicants: `${leadBaseApi}/Applicants`,
    referenceData: `${referenceBaseApi}/api/Reference`,
    mortgageDocs: `${leadBaseApi}/Documents`,
    loanOffers: `${leadBaseApi}/LoanOffers`,
    user: `${leadBaseApi}/User`,
    newsLetter: `${leadBaseApi}/Newsletter`,
    waitList: `${leadBaseApi}/Waitlist`,
    salesAgencies: `${leadBaseApi}/SalesAgencies`,
    brokerages: `${leadBaseApi}/Brokerages`,
    brokers: `${leadBaseApi}/Brokers`,
    documents: `${baseUrl}/Documents`,
    listingProperty: `${baseUrl}/listingproperty`,
    listingLandlord: `${baseUrl}/landlord`,
    blocksOnboarding: `${leadBaseApi}/Onboarding/Blocks/Lead`,
    dueDiligence: `${baseUrl}/duediligence`,
    bank: `${leadBaseApi}/banks`,
    notificationHub: `${baseUrl}/hub`,
    teams: `${leadBaseApi}/Teams`,
    propertyInvestment,
    propertyInvestmentCheckout: `${propertyInvestment}/Orders/Checkout`,
    blocks: {
      investor: `${propertyInvestment}/investor`,
    },
    dev: {
      lead: `${leadBaseApi}/dev`,
      user: `${userBaseApi}/dev`,
    },
  },
};
