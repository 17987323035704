import { Injectable, inject } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';

import { ENV, Environment } from '@aw/prypco/environment';

import { GtmService } from './gtm.abstract';

@UntilDestroy()
@Injectable()
export class GtmServiceImpl extends GtmService {
  private readonly env = inject(ENV) as Environment;
  
  private dataLayer = ((window as any).dataLayer || []) as Array<{}>;

  pushEvent(trackEvent: object): void {
    if (this.env.externalScripts.gtm) {
      this.dataLayer.push({ ecommerce: null });  
      this.dataLayer.push(trackEvent);
    }
  }
}
